

  
    .title{
          font-size: 38px;
        line-height: 60px;
        font-family: Roboto,sans-serif;
            font-weight: 600;
        }

.data-underline1{
    text-decoration: underline 3px solid #2F3092 ;
    text-underline-offset: 20px;
    
    }






/* part 2 */




.list-ul ul li a{

text-decoration: none;
color: black;

}









/* part 3 */

.epr-container{

width: 100%;
margin: 0px ;
padding: 0px;



}
.list-title1{

font-size: 32px;


}




.epr-width{

/* max-width: 1140px; */
/* border: 1px solid black; */
display: flex;

padding-left: 180px;


}

.epr-left{
width: 40%;
/* border: 1px solid black; */

}



.epr-right{

width: 50%;



}

.epr-image{

    width: 100%;

    height: 500px;
    object-fit: cover;
    /* object-position: center center; */
margin-bottom: 50px;

/* border: 1px solid black; */
}


.epr-image img{
      width: 85%;
    vertical-align: middle;
    display: inline-block;
    



}

@media screen and (max-width:1024px)  and (min-width:300px){
    

    .epr-container{
   width: 100%;
   
    }
       

    .epr-width{

        /* max-width: 1140px; */
        /* border: 1px solid black; */
        display: block;
        
        padding-left: 10px;
        
        
        }

        .epr-left{
            width: 100%;
            /* border: 1px solid black; */
            
            }





            .list-title1{

                font-size: 30px;
                
                
                }





                .epr-image{

                    width: 100%;
                        margin-top: 50px;
                    height: 500px;
                    object-fit: cover;
                    /* object-position: center center; */
                margin-bottom: 50px;
                
                /* border: 1px solid black; */
                }
                
                
                .epr-image img{
                      width: 100%;
                    vertical-align: middle;
                    display: inline-block;
                    
                
                
                
                }



                .epr-right{

                    width: 100%;
                    
                    
                    
                    }





}














