


 .purchase-span{


text-decoration: underline;


}























