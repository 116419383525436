body{

    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    
    }
    
    .count-container{
  margin-top: 100px;
    width: 100%;
    height: 100%;
    
    }
    .count-container .count-width{
    
    width: 1140px ;
    margin: 20px auto;
    position: relative;
    display: flex;
    gap: 20px;
    
    }
    
    .count-container .count-width .count-col{
    
    border: 3px solid black;
    width: 30%;
    height: 200px;
    border-radius: 30px;
    padding: 0px;
    
    }


    
    
    .count-container .count-width .count-col .count-content{
    
    /* border: 1px solid black; */
    /* height: 50px; */
    
   
    
    }
    
      .count-content  .count-number{
    
    /* border: 1px solid black; */
    text-align: center;
    font-size: 65px;
    font-weight: 700;
    color: #2f3092;
    
      }
    
    
    .count-content .count-title{
    
        font-family: "Arial", Sans-serif;
        font-weight: 700;
        text-align: center;
        font-size: 19px;
    color: #111234;
        line-height: 2;
    
    
    
    }
    
    
    @media screen  and (max-width:1024px)  and (min-width:300px) {
        
    .count-container .count-width{
    width: 100%;
    display: block;
    text-align: center;
    gap: 0px;
    
     } 
    
    .count-container .count-width .count-col{  
    width: 90%;
    border-radius: 30px;
    margin: 50px auto;
    }
    
    
    }
    
    
    
    
    
    
    
    
    