.container-fluid {
  height: 130px;

  background-color: #fff;
  padding-top: 25px;
}

.nav-com.navbar-nav.navbar-nav-scroll {
  height: auto;
}

#navbarScrollingDropdown.navbar-collapse.collapse.show {
  background-color: #ffffff;
  padding: 0px;
}
.brand {
  padding-top: 0px;
  padding-left: 100px;
}

.brand img {
  width: 150px;
  height: 75px;
}

button.nav-toggle.navbar-toggler {
  padding: 2px 5px;
  /* margin-right: 10px; */
  margin-top: 0px;
}

.dropdown-menu.show {
  z-index: 1000;

  padding: 0px;
}

#navbarScrollingDropdown.dropdown-toggle.nav-link {
  color: #2f3092;
  z-index: 1000;
}

.nav-link {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 15px;
  color: #000000;
  text-decoration: none;
  list-style: none;

  margin-bottom: 0px;
}
.nav-link::before,
.nav-link::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #2f3092;
  transition: 0.5s;
  margin-top: 10px;
}

.nav-link::after {
  left: 0px;
  bottom: 5px;
  transform: scaleX(0);
}

.nav-link::before {
  right: 0px;
  top: -5px;

  transform: scaleX(0);
}

.nav-link:hover:before,
.nav-link:hover::after {
  transform: scaleX(1);
  width: 100%;
}

.dropdown-link {
  position: relative;
  display: inline-block;

  padding: 15px;
  color: #000000;
  text-decoration: none;
  list-style: none;

  margin-bottom: 0px;
  font-size: 16px;
}
.dropdown-link::before,
.dropdown-link::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #0764b1;
  transition: 0.5s;
  margin-top: 10px;
}

.dropdown-link::after {
  left: 0px;
  bottom: 10px;
  transform: scaleX(0);
}

.dropdown-link::before {
  right: 0px;
  top: -5px;

  transform: scaleX(0);
}

.dropdown-link:hover:before,
.dropdown-link:hover::after {
  transform: scaleX(1);
  width: 100%;
}

.active {
  position: relative;
  display: inline-block;

  padding: 15px;
  color: blue;
  text-decoration: none;
  font-size: 16px;
}

#link-to {
  text-decoration: none;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}
#link-to:hover {
  color: #0764b1;
}

.dropdown1 {
  color: #0764b1;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.dropdown1.active {
  color: #2f3092;
}

.dropdown1:hover {
  color: #0764b1;
}
.dropdown-item {
  height: auto;
  width: 250px;
  padding: 15px;
  z-index: 1000;
  margin: 0px;
  position: relative;
}

.main-nav {
  height: 60px;
  margin: 0px;
  background-color: white;
  padding: 0px;
}

.nav-com {
  gap: 0px;
  color: black;
  font-size: 14px;
  font-weight: bolder;
}

span.dropdown1 {
  font-size: 14px !important;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  width: 100%;
  padding-top: 20px;
}

.dropdown-items {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  z-index: 1000;
  height: auto;
}
.single-item {
  border-bottom: 1px solid #000000;
  color: #000000;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.single-item:hover {
  color: #0764b1;
  background-color: white;
  cursor: pointer;

  font-weight: bold;
}

.border {
  text-align: center;
}
.border:hover {
  color: #0764b1;
  font-weight: bold;
  cursor: pointer;
  background-color: #ffffff;
}

.demo {
  margin-left: 75%;
  height: 30px;
  width: 100px;
  font-weight: bold;
  fill: #ffffff;
  color: #ffffff;
  padding-left: 18px;
  padding-top: 7px;
  position: absolute;
  font-size: 11px;
  background-color: #2f3092;
  border-radius: 50px 50px 50px 50px;
  cursor: pointer;
}

.demo span {
  text-decoration: inherit;
}

@media screen and (max-width: 1040px) and (min-width: 340px) {
  .demo {
    margin-left: 36%;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    display: none;
  }

  .main-section {
    margin-bottom: 50px;
    padding-top: 0px;
  }

  .dropdown {
    text-align: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid black;
    z-index: 1000;
    background-color: #ffffff;
  }

  .dropdown-link {
    text-align: center;
    border-bottom: 1px solid black;
    z-index: 999;
    background-color: #fff;
  }

  .container-fluid {
    height: auto;
    padding-top: 2px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .nav-com.navbar-nav.navbar-nav-scroll {
    margin: 0px;
  }

  #navbarScrollingDropdown.dropdown-toggle.nav-link {
    padding: 10px;
  }

  .dropdown-item {
    width: 100%;
    height: auto;
  }

  .brand {
    padding: 0px;
  }

  .nav-com {
    gap: 0px;
    padding-left: 0%;
  }

  .nav-link {
    padding: 10px;
  }
  .dropdown-item {
    padding: 5px;
  }
}
