
.point-container{
    background-color: #EDE8E8;
    width: 100%;
    padding: 0px ;
    margin: 0px;
    box-sizing: border-box;
}

.point-width{
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
width: 1140px;
padding-top: 50px;



}

.p-left{

    width: 50.024%;



}

.p-detail{

width: 100%;
padding: 20px;


}

.p-detail h2{

padding: 30px 0px ;
width: 100%;
/* border: 1px solid black; */

}

.p-detail .p-title{

text-decoration:5px underline;
text-underline-offset: 30px;
text-decoration-color: #05A0D2;

}

.p-detail p {

text-align: justify;
    margin-bottom: 20px;
    margin-top: 30px;
}


.p-right{

    width: 49.976%;

}

.p-image{

   text-align: center;
padding: 40px 0px ;
}


.p-image img{

    vertical-align: middle;
    display: inline-block;

}



@media screen and (max-width:1024px)  and (min-width:300px)
{

    
.point-width{

display: block;
width: 100%;

}

.p-left{

width: 100%;
/* padding: auto; */


}
.p-right{

width: 100%;

}




}



/* part 2  */



.dash-con{

width: 1140px;
text-align: center;
margin-left: auto;
margin-right: auto;
}

.dash-con h3{

padding: 40px 0px ;
font-size: 32px;
font-family: Roboto,sans-serif;
font-weight: 600;


}


.dash-image{

width: 100%;
margin-bottom: 30px;


}


.dash-image img {

width: 98%;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.64);
}




@media screen and (max-width:1024px)  and (min-width:300px)
{

.dash-con{

width: 100%;
margin-left: 0px;
margin-right: 0px;


}
.dash-image{

width: 98%;
padding: 0px 10px;
}

.dash-image img {

width: 100%;



}








}




