body{


    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
   


}

.dimage-section{

   
    margin: 0px;
    padding: 0px;
    width: 100%;
    position: relative;
    }
    
    .dimage-container{
       
    width: 100%;
    padding: 10px 0px 0px 0px ;
    position: relative;
    justify-content: center;
    align-items: center;



    }
    
    .dimage-width{

   max-width: 1140px;
   height: auto;
/* border: 1px solid black; */
display: flex;
position: relative;
justify-content: center;
text-align: center;
padding: 0px  20px;
margin: 0px 200px ;


    }

.dimage-width .dimage-col{
/* padding: 10px 20px ; */
margin: 10px;
width: 30%;
height: 250px;
/* border: 1px solid black; */
flex-wrap: wrap;

}

.dimage-col img{
width: 100%;
/* object-fit: contain; */
height: 150px;
}

.dimage-col .dimage-title{

margin: 10px;
padding: 10px ;
line-height: 40px;
font-size: 14px ;
transition: all ease-out .3s ;
font-weight: bold;
    fill: #000000;
    color: #000000;
    background-color: #02010100;
    box-shadow: 0px 0px ;
    text-transform: uppercase;

}

.dimage-title:hover{

color: blue;
transform: scale(1.1);

}

@media screen and (max-width:1024px) and (min-width:300px){
    
    .dimage-container{
  width: 100%;
  padding: 0px ;
  margin: 0px ;

    }
    .dimage-col{

margin-bottom: 20px;
 margin-left: 30px;

    }
    .dimage-col .dimage-title {
          /* margin-bottom: 30px; */
        line-height: 20px;
    }

    .dimage-width{

width: 100%;
padding: 0px;
margin: 0px 0px ;
 display: block;

    }

.dimage-width .dimage-col{
      
    margin: 0px;
    width: 90%;
    height: 250px;
    margin: 0px auto;


}
}





