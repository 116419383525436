.t-main{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    font-family: sans-serif;
    box-sizing: border-box;
}
.t-Section_top{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/julian-mora-cyOpW2k_xe0-unsplash-1.jpg;);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    justify-content: center;
    transition:all 5s;

    
    animation: change  infinite ease-in-out ;
    animation-delay: 5s;
}


.t-content{
    position: absolute;
    top: 30%;
    left: 22%;
    transform: translate(-50%, 0%);
    text-transform: uppercase;
width: 100%;
/* border:  1px solid black; */
}
.t-content h1{
    color: #fff;
    font-size: 55px;
    left: 0px;
 
    width: 100%;
    font-family: Roboto,sans-serif;
    font-weight: 600;
}

.t-content p {
    margin: 30px 0px;
    color: #fff;
    font-size: 20px;
    font-family: Roboto,sans-serif;
    font-weight: 600;
    padding-bottom: 100px;
    padding-left: 170px;

}

   .t-button{

width: 100%;
/* border: 1px solid black; */
height: auto;
left: 0px;

}

.t-button a{
    background:transparent;
    padding: 10px 24px;
    text-decoration: none;
    font-size: 18px;
    margin-top:50px ;
 border-color: white;
    border-style: solid;
    color: white;
    width: 100%;
    margin-right: 345px;
}



.t-button a:hover{
 
    color: black;
    border-color: black;
}

@keyframes change{
    0%
    {
        
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/vladimir-proskurovskiy-IB6wKbPIvNA-unsplash-1.jpg);
  
   
   
    }
    20%
    {
        background-image: url(https://media.istockphoto.com/id/1455865045/photo/fashion-designers-working-together-at-a-clothing-atelier.jpg?s=2048x2048&w=is&k=20&c=uvX0jVrvjSn3KmGKGeuzf8LVlm0iempTDtjHK3FlTXk=);
      
   
    }
    40%
    {
        background-image: url(https://plus.unsplash.com/premium_photo-1674617405385-5fb6b3079319?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
       
    }
    60%
    {

        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/julian-mora-cyOpW2k_xe0-unsplash-1.jpg;);
   
  
    }
    80%
    {
        
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/julian-mora-cyOpW2k_xe0-unsplash-1.jpg;);
   
   
    }
    100%
    {
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/vladimir-proskurovskiy-IB6wKbPIvNA-unsplash-1.jpg);
  
    }
}



@media screen and (max-width:1024px)and (min-width:300px) {
    
.t-Section_top{

width: 100%;
text-align: center;

}
.t-content{

width: 100%;

}

.t-content h1{

font-size: 32px;
margin-left: 80px;
width: 100%;

}

.t-content p{

width: 100%;
padding-left: 10px;
text-align: center;
margin-left: 120px;
/* border: 1px solid black; */
line-height: 50px;
padding-bottom: 30px;

}

.t-button a{

margin-right: 10px;
margin-left: 50px;

}


}










