
body{

margin: 0px;
padding: 0px ;
box-sizing: border-box;



}

.title.project{

margin: 60px 0px;




}









































