







body{

box-sizing: border-box;
margin: 0px ;
padding: 0px;
position: relative;


}


.soft-containter{
margin-top: 20px;
width: 100vw;
height: auto;


}

.soft-width{

width: 100%;
padding: 50px;
margin-top: 10px;
background-color: #ede8e8;

}
.soft-detail{

    width: 100%;
 justify-content: center;
text-align: center;


}

.soft-title  {
display: block;
font-size: 32px ;
font-weight: 600;
font-family:Roboto,sans-serif;

padding: 20px;
margin-bottom: 15px;

}


.soft-title .soft-pp {
  display: inline-block;
  font-size: 32px ;
  font-weight: 600;
  font-family:Roboto,sans-serif;

  
  }

.soft-btn1 {
    display: inline-block;
    line-height: 50px;
    padding: 1px 30px 15px 30px;
    background-color: #2F3092;
    color: #fff;
    text-align: center;
    width: 280px;
    height: 50px;
    text-decoration: none;
    border-radius: 0px;
    transition:  0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
    cursor: pointer;
    font-family: "Arial", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
   
  }





  .soft-btn1:hover {
    background-color: #2F3092;
    color: #fff;

    transform: scale(1.1); 
  }


@media screen  and (max-width:1024px)  and (min-width:300px) {


  .soft-width{
   
    padding: 10px;
    
    }



  .soft-title{
       font-size: 30px;
    padding: 30px 0px;
    }
    
    .soft-title .soft-pp{
      
        display: block;
        padding-top: 10px;
    }
  
}
