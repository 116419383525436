



.cash-banner{

width: 100%;


}

.cash-img{

width: 100%;
position: relative;

}

.cash-img img{


width: 100%;
height: 400px;

}

.cash-banner h1{
color: white;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-90%,-50%);
z-index: 9;
}


@media screen and (max-width:1024px)  and (min-width:300px){

    .cash-img{
width: 100%;


    }

.cash-img img{

width: 100%;
height: auto;


}
.cash-banner h1{

width: 100%;
transform: translate(-50%,-50%);
text-align: center;

}

    
}



/* part 2 */


.hrattend-container.cash{

/* border: 1px solid black; */
width: 1240px;
margin: 0px 200px;
text-wrap: wrap;
}

.cash-attend-col{

/* border: 1px solid black; */
margin:0px auto;
width: 100%;

}




.hrattend-heading.cash{

/* border: 1px solid black; */
margin-left: 0px;
width: 100%;
margin-top: 100px;

}
 .cash-attend-col .attend-p.cash{

padding-left: 20px;
width: 100%;
font-weight: 400;
color: #000;
}

.attend-p.cash p{

width: 100%;
/* border: 1px solid black; */

}


@media screen   and (max-width:1200px) and (min-width:300px) {
    

.hrattend-container.cash{

width: 100%;
margin-left: 0px;

}

.cash-attend-col{

width: 100%;
margin-left: 0px;


}

.hrattend-heading.cash{

width: 100%;



}



}




   /* part 6 */

   hrpay-image{


    width: 100%;
    
    
    
    }
    
    
   
    
    @media screen   and (max-width:1024px) and (min-width:300px ){
    
    .hrpay-container{
    
    text-align: start;
    
    }
    
    .cash-img {
    width:100% ;
       padding-left: 15px; 
    }
    
    
    
    .cashpay-image img{
    
    
        width: 95%;
    
    }
    
    }




/* part 7  */


.cash-attend-title{

font-size: 30px;
/* color: #000; */
padding-bottom: 30px;
font-weight: 700;
}



.cash-help{


font-style: italic;


}
b{

color: #2F3092 ;
font-style: italic;

}






