


body{

 width: 100%;
 height: 100%;
 padding: 0px;
 margin: 0px;



}

.labms-title{

font-size: 25px;
margin: 5px;
/* border: 1px solid black; */

}

.labtory{

font-size: 45px;




}












