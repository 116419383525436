.t-main{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    font-family: sans-serif;
    box-sizing: border-box;
}

.trading-overlay{
width: 100%;
height: 100vh;
background-color: rgba(0, 0, 0, 0.448);
/* z-index: 9; */
position: absolute;

}


.t-Section_top.trading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/Trading-Industry-Multi-Techno.jpg);

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    justify-content: center;

    animation: animate 10s infinite ease-in-out ;
    animation-delay: 10s;
}





.t-content.trading{
    position: absolute;
    top: 30%;
    left: 40%;
    transform: translate(-50%, 0%);
    text-transform: uppercase;
width: 100%;
color: #fff;
/* z-index: 15; */
/* border:  1px solid black; */
}
.t-content h1{
    color: #fff;
    font-size: 55px;
    left: 0px;
 
    width: 100%;
    font-family: Roboto,sans-serif;
    font-weight: 600;
}
.h1-trading{

padding-left: 70px;


}


.t-content p {
    margin: 30px 0px;
    color: #fff;
    font-size: 20px;
    font-family: Roboto,sans-serif;
    font-weight: 600;
    padding-bottom: 100px;
    padding-left: 170px;

}

   .t-button{

width: 100%;
/* border: 1px solid black; */
height: auto;
left: 0px;

}

.t-button.trading a{
    background:transparent;
    padding: 10px 24px;
    text-decoration: none;
    font-size: 18px;
    margin-top:50px ;
 border-color: white;
    border-style: solid;
    color: white;
    width: 100%;
    margin-right: 58%;
}



.t-button a:hover{
 
    color: black;
    border-color: black;
}

@keyframes  animate{
  
    20%
    {
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/Trading-Industry-Multi-Techno.jpg);
      /* z-index: 5; */
      animation-delay: 5s;
    }
    40%
    {
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/Trading-Industry-Multi-Techno2.jpg);
        /* z-index: 5; */
        animation-delay: 10s;
    }
    60%
    {

        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/Trading-Industry-Multi-Techno.jpg);
        /* z-index: 5; */
        animation-delay: 10s;
  
    }
    80%
    {
        
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/Trading-Industry-Multi-Techno2.jpg);
        animation-delay: 10s;
        z-index: 5;
    }
    100%
    {
        background-image: url(https://multi-techno.com/wp-content/uploads/2022/08/Trading-Industry-Multi-Techno.jpg);
        z-index: 5;
        animation-delay: 10s;
    }
}



@media screen and (max-width:1024px)and (min-width:300px) {
    
.t-Section_top.trading{

width: 100%;
text-align: center;

}
.t-content.trading{

width: 100%;
left: 0%;
/* text-align: center;
justify-content:center; */
margin-left: 96px;
}

.t-content.trading h1{

font-size: 30px;
margin-left: 120px;
width: 100%;
/* border: 1px solid black; */

}

.h1-trading{

padding-left: 0px;

}




.t-content.trading p{

width: 100%;
padding: 10px;
text-align: center;
margin-left: 125px;
/* border: 1px solid black; */
line-height: 50px;
padding-bottom: 30px;
font-size: 17px;


}

.t-button.trading a{

margin-right: 2%;
margin-left: 50%;

}


}







/* part 3  */

.epr-container.trading{

margin-top: 60px;



}





