body{

margin: 0px ;
padding: 0px ;
box-sizing: border-box;

}






.container-fluidimg{

    width: 100%;
    display: flex;
    /* flex-wrap: wrap; */
    position: relative;
    padding: 90px;
    
    
    }
    
    .image-left{
    
    width: 50%;
    padding:0px 40px;
    
    
    }




    .image-left .image-div{
    
    width: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
    transition: 0.5s;
    cursor: pointer;
    
    }


    
    
    
    
    .image-left img{
        height: auto;
        max-width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
    
    }
    
    
    
    
    .image-rightcontent{
    
    width: 50%;
    padding: 0px 30px;
    color: #000000;
    
    }
    .content-title{
    
    padding-bottom: 10px;
    
    
    }
    
    
    
    .content-detail{
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 30px;
    font-family: "Arial", Sans-serif;
    
    
    }
    
    
    
    .custom-button {
        display: inline-block;
        padding: 15px 40px 15px 40px;
        background-color: #2F3092;
        color: #fff;
        text-align: center;
        text-decoration: none;
        border-radius: 0px;
        transition:  0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
        cursor: pointer;
        font-family: "Arial", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0px;
      }
    
      /* Button hover effect */
      .custom-button:hover {
        background-color: #2980b9;
        color: #fff;
        transform: scale(1.1); 
      }
    
    
    
    
    @media screen and   (max-width:1024px) and (min-width:440px)     {
        
        .container-fluidimg{
    
    display: block;
    padding: 10px;
    
        }
    
        .image-left{
          width: 100%;
          padding: 0px 20px;
        }
    
        .image-rightcontent {
    width: 100%;
    padding:0px 0px  0px 10px;
    margin-top: 18px;
    
        }
    
.content-title{

padding:0px 0px;
width: 100%;


}
    
    
    
    
    
        .custom-button {
       font-size: 14px;
    width: 200px;
    
    
        }
    
    }
    




/* section number 2  */


.back-ground-image{
    background-attachment: fixed;
    background-image: url(https://multi-techno.com/wp-content/uploads/2023/04/rawpixel-799380-unsplash.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 0px 40px 0px;
    box-sizing: border-box;
    width: 100%;
    
    
    
    
    
    }
    
    
    
    
    
    .back-ground-image>.elementor-background-overlay {
        
        background-color: #f5f7fa;
        opacity: 0.97;
        transition: background 0.3s, border-radius 0.3s,
         opacity 0.3s;
         height: 100%;
         width: 100%;
         top: 0;
         left: 0;
         position: absolute;
    
    
    }
    
    .about-main{
        max-width: 1040px;
        text-align: center;
    
        display: flex;
        margin-right: auto;
        margin-left: auto;
        position: relative;
    
    }
    
    .about-main   .about-data{
    
    width: 100%;
    text-align: center;
    align-items: center;
        width: 100%;
    }
    
    .title{
    
    line-height: 60px;
    font-family: Roboto,sans-serif;
        font-weight: 600;
    }
    .data-underline{
    text-decoration: underline 3px solid   #05A0D2;
    text-underline-offset: 10px;
    
    }
    
    .about-detail{
    
        margin-block-start: 0;
        margin-bottom: 30px;
       font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    align-items: center;
        padding:0px 20px;
    }
    
     .about-image img{
    width: 1040px;
    
    
    
    }
    
    
    
    
    @media screen  and (max-width:1024px) and (min-width:440px){
     
        
      .about-image img{
    
    width: 90%;
    
    
    }
    
    .back-ground-image{
    
    margin-bottom: 50px;
    
    
    }
    
    }
    
    













   /* section number 3 start */


   .elementor-section{
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #2F3092 7%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 40px;
    margin-bottom: 20px;


}

.elementor-section .elementor-container {
    max-width: 1140px;
}
.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}
.elementor-column {
    width: 100%;
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    
    align-content: flex-start;
}


.elementor-widget-wrap>.elementor-element {
    width: 100%;
}

.elementor-container .elementor-element{
    margin: 12px 0px 12px 0px;
}


.elementor-element .elementor-heading {
    color: #FFFFFF;
    font-size: 35px;
    margin: 30px 0px;
}

.elementor-heading {
    padding-left:390px ;
    margin: 0;
    line-height: 1;

}


.elementor-heading .about {
    padding-left:450px ;
    margin: 0;
    line-height: 1;

}
.fact-container{

width: 1140px ;
margin: 20px auto;

text-align: center;

}




.fact-aboutus{

padding: 10px;
color: #000000;
font-weight: 500;


}




@media screen  and (max-width:1024px) and (min-width:440px){
 
    .elementor-heading{
padding-left: 0px;


    }
    
  .fact-container{
    width: 100%;
  }

  .elementor-heading {
    /* padding-left:10px ; */
    margin: 0;
   justify-content: center;
   align-items: center;
   text-align: center;
    line-height: 1;

}





  }



